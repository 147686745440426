.pane-clinician-search-sidebar {
    display: inline-block;
    max-width: 22rem;
    margin-top: -1.5rem;
    margin-bottom: 2rem;
    //border-bottom: 1px solid #dddddd;
    text-align: left;
    @include breakpoint($lg) {
      max-width: 25.5rem;
    }
    .clinician-search-wrapper {
      background: #cf4520;
      border-radius: 5px;
      padding: 1rem;
      color: $wcm-white;
      .clinician-search-title {
        font-size: 2rem;
        line-height: 1.5;
        padding: 0 0.5rem;
        margin-bottom: 0;
      }
    }
    #clinician-search-form {
      width: 220px;
      margin: 0 auto;
      @include breakpoint($md) {
        width: 245px;
      }
      @include breakpoint($lg) {
        width: 255px;
      }
      .form-actions {
        margin-bottom: -2.8rem;
        .form-actions-wrapper {
          position: relative;
          input[type="submit"] {
            position: absolute;
            height: 3.9rem;
            width: 3.9rem;
            background: #e97623 none repeat scroll 0% 0%;
            border: medium none;
            border-radius: 0px 3px 3px 0;
            margin-right: 0;
            bottom: 3rem;
            right: 1.5rem;
            @include breakpoint($md) {
              right: 4.5rem;
            }
            @include breakpoint($lg) {
              right: 1.8rem;
            }
          }
          .cta-icon-search {
            position: absolute;
            color: $wcm-white;
            fill: $wcm-white;
            font-size: 1.8rem;
            cursor: pointer;
            bottom: 4rem;
            right: 2.5rem;
            @include breakpoint($md) {
              right: 5.5rem;
            }
            @include breakpoint($lg) {
              right: 2.8rem;
            }
          }
        }
      }
      .form-item {
        #edit-keyword {
          font-size: 1.15rem;
          margin-top: 0.8rem;
          width: 100%;
          display: block;
          max-width: 20rem;
          padding: 2px 5px;
          @include breakpoint($lg) {
            font-size: 1.2rem;
            max-width: 23.5rem;
            padding: 4px 10px;
          }
        }
        #autocomplete {
          font-size: 1.3rem;
          background: #fff;
          border: 1px solid #ddd;
          margin-top: 0.8rem;
          li {
            white-space: normal;
            padding: 4px 2px;
          }
        }
      }
      #edit-search-type {
        padding-left: 0.8rem;
        .form-item-search-type {
          margin-bottom: 0rem;
          input[type="radio"] {
            margin-top: 0.6rem;
          }
        }
      }
    }
}